import React from "react"
import Layout, {withCenter} from "../components/layout"
import SEO from "../components/seo"
import {graphql, Link} from 'gatsby'
import { BoxProps } from "rebass";
import { Box, Heading, Text, Flex } from "../components/responsiveComponents";
import Img from "gatsby-image";
import { SectionTitle } from "../components/title";
import styled from "styled-components";
import {fluidImage} from "../components/fragments"
import { Project, ProjectList } from "../components/projects";
import { outerSpacing, twoColGrid } from "../theme/spacing";
import { light, dark } from "../theme/colors";
import { InternalLink } from "../components/link";
import { Gallery } from "../components/gallery";
import ContactUs from "../components/contact";
import arches from "../images/arches.png"


interface FieldProps {
    label: string,
    value: string,
    boxProps: BoxProps
}

interface ProjectDetailProps {    
    project: Project,
    childBoxProps: BoxProps,
    boxProps: BoxProps
}

const InnerContainer = withCenter(Box)

export const FieldLabel = styled(Text).attrs({
    fontSize: 1,
    color: "neutral.7"
})`
    text-transform: uppercase;
    letter-spacing: 0.07em;
`
const Field = (props: FieldProps) => {
    return (
        <Box {...props.boxProps}>
            <FieldLabel>{props.label}</FieldLabel>
            <Text>{props.value}</Text>
        </Box>
    )
}

const ProjectDetail = (props: ProjectDetailProps) => {
    return (
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" {...props.boxProps}>
            <Field label="Location" value={props.project.location} boxProps={props.childBoxProps}/>
            <Field label="Type" value={props.project.type} boxProps={props.childBoxProps}/>
            <Field label="Category" value={props.project.category} boxProps={props.childBoxProps}/> 
            <Field label="Year" value={props.project.year} boxProps={props.childBoxProps}/>
        </Flex>
    )
}

const Divider = styled(Box)`
  height: 3px;
`

export default ({data}) => {  
  return (
    <Layout>
      <SEO 
        title={`${data.project.name} - ${data.project.type} ${data.project.category}`}
        keywords={[`interior design`, `contractor`, `architecture`, data.project.category]} />      
      <InnerContainer {...outerSpacing} {...dark}>
        <Heading as="h3" fontSize={4}>{data.project.name}</Heading>
        <Divider width="80px" bg="primary.4" my="vert.2"/>
        <ProjectDetail 
            project={data.project} 
            boxProps={{
                my:"vert.5",
                width: [1, 0.8],            
            }}
            childBoxProps={{
                width: [1/2, 0.25],
                my: "vert.2",
                pr: [2]
            }} />        
        <Gallery 
            images={data.images.nodes} 
            widthPatterns={[[1,2,2], [1,2], [2,1]]} 
            margin={0.04}
            boxProps={{
                mt: "vert.5"
            }}
            childBoxProps={{
                py: "vert.1",
                my: "vert.6"
            }}
        />   
      </InnerContainer>
      <InnerContainer {...outerSpacing} {...light}>
        <SectionTitle>More Projects</SectionTitle>        
        <ProjectList
            projects={data.related.nodes} 
            images={data.allProjectImages.nodes} 
            titleColor="neutral.0" 
            subtitleColor="neutral.1"
            childBoxProps={{
                ...twoColGrid,
                py: "vert.1",
                my: "vert.3",
            }}
            boxProps={{
                mt: "vert.5"
            }}
        />
        <InternalLink color="primary.4" my={"vert.2"} hovercolor="primary.6" to="/projects">See All Projects ></InternalLink>
      </InnerContainer>
      <InnerContainer {...outerSpacing} {...light} backgroundImage={`url(${arches})`}>
        <ContactUs  />
      </InnerContainer>
    </Layout>
  )
}

export const query = graphql`    
    query($slug: String!, $projectImageFolder: String, $related: [String]) {
      
    project: 
        projectsYaml(slug: { eq: $slug } ) {
            name
            location
            year
            type
            category      
        }
    
    images: 
        allFile(
            filter: {relativeDirectory:{eq: $projectImageFolder}},
            sort: {fields:[relativeDirectory, base]}) {
            nodes {
                ...fluidImageLarge 
            }
        }            

    related: 
        allProjectsYaml(filter: {id: {in: $related}}) {
            nodes {
                name
                location
                slug
                imageFolder
            }
        }

    ...allProjectImages
  }
`