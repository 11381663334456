import React from "react"
import { Image } from "../components/projects";
import { Box, Flex } from "../components/responsiveComponents";
import Img from "gatsby-image";
import {times, constant, zip, last, some} from "lodash";
import { BoxProps } from "rebass";
import { runningTotal } from "../utils/runningTotal";

interface GalleryProps {    
    images: Image[],
    widthPatterns: number[][],
    margin?: number,
    childBoxProps?: BoxProps,
    boxProps?: BoxProps
}

const findPattern = (patterns: number[][], size: number) => {
    for(const pattern of patterns) {
        const runningTotalArr = runningTotal(pattern)
        const patternSum = last(runningTotalArr) || 1
        const mod = size % patternSum 
        if (mod === 0 || some(runningTotalArr, (val => val === mod))) {
            return pattern;            
        }
    }

    return patterns[0];
}

const repeatPattern = (pattern: number[], size: number) => {
    const result = []
    while(true) {
        for(const num of pattern) {        
            const values = times(num, constant(num))
            result.push(...values);

            if (result.length >= size) {
                return result;
            }
        }
    }    
}

const generateWidths = (images: Image[], pattern: number[], margin=0.04) => {    
    const repeatedArray = repeatPattern(pattern, images.length)
    const widths = repeatedArray.map(ratio => {
        const width = 1 / ratio
        const adjusted = width < 1 ? width - margin : width
        return adjusted
    })
    return zip(images, widths)
}

export const Gallery = (props: GalleryProps) => {    
    const pattern = findPattern(props.widthPatterns, props.images.length)
    const imageAndWidths = generateWidths(props.images, pattern, props.margin)
    
    const images = imageAndWidths.map(([node, width]: [Image | undefined, number | undefined]) => (  
     node &&
      <Box width={[1, width]}         
        {...props.childBoxProps}
        key={node.childImageSharp.fluid.src}
        >

        <Img fluid={node.childImageSharp.fluid} />
      </Box>
    ))

    return (
        <Flex flexDirection="row" flexWrap="wrap" justifyContent="space-between" {...props.boxProps}>
          {images}
        </Flex>
    )    
}